.rootContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 10px #ddd;
    padding: 125px 0;
}


.loginForm .ant-form-item {
    margin-bottom: 15px !important;
    width: 210%;
}

.ant-form-item-label {
    padding: 0px !important
}
.ant-form-item-control {
    width: 95% !important
}


.loginForm .inputBox {
    border-radius: 5px;
    border-color: #aaa;
    width: 50% !important;
}

.loginForm button {
    width: 100%;
    float: right;
}
 .textCenter .loginImg{
    width:100%;
    margin-left: -15%;
}
@media (max-width: 500px) {
    .textCenter .loginImg{
        width:80%;
        margin-left: 10px;
    }
    .rootContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
        margin-top: 20%;
        width: 180%;
    }
    .loginForm{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loginForm .inputBox {
        border-radius: 5px;
        border-color: #aaa;
        width: 100% !important;
    }
    
    Form .ant-form-item{
        max-width: 100%;
    }
    
}