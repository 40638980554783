.title {
    margin-left: -1%;
    margin-top: 20%;
    font-size: 25px;
    font-weight: 30px;
    color: #9e713f
}
.profileAvatar {
    color:#99c3e8;
    background-color: #f7f5f9;
}

.icon {
    font-weight: -20%;
    margin-top: 30%;
    margin-left: 30px;
    
}
.ant-form-item-label {
width: 120px;
text-align: start;
}
.profile {
    display: flex;
    justify-content: space-evenly;
}

.profileForms {
     padding: 30px;
    margin-top: 12%; 
    margin-left: -10%;
    /* height: 350px; */
    /* border: 3px solid #9e713f; */
}
.formItems {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.eachForm{
    margin-right: -13%;
}