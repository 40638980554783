body {
  margin: 0;
  font-family: sans-serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #ecedef !important;
  padding: 2px;
  /* color: #fba03a !important; */
  color: #000 !important;
  font-size: 17px !important;
  line-height: 17px;
}

h3 {
  font-size: 17px;
}

.textCenter {
  text-align: center !important;
}

.textRight {
  text-align: right !important;
}

#root {
  padding-bottom: 1px;
}

.componentsLoadContainer {
  margin: 15px;
  border-radius: 10px;
  background-color: #fff;
  min-height: 550px;
  /* overflow: auto; */
}

.tableTop {
  display: flex;
  gap: 20px;
  padding: 0px 16px;
}

/* Button Styles */

button.ant-btn {
  color: #fff !important;
  border: 0px;
  border-radius: 5px;
}

button.ant-btn:hover,
button.ant-btn:focus {
  background-color: #9c5403 !important;
}

button.ant-btn-primary {
  background-color: #9c5403 !important;
  color: #fff;
  border: 0px !important;
  border-radius: 5px !important;
}

button.ant-btn-primary:hover {
  background-color: #fba03a !important;
  color: #000;
}

.ant-btn-secondary {
  background-color: #bebebe !important;
}

.ant-btn-secondary:hover {
  background-color: #555555 !important;
}

.ant-btn-link {
  color: red !important;
}

.ant-btn-link:hover {
  background-color: none !important;
}

.ant-ribbon-corner {
  display: none;
}

.ant-table table,
.ant-table table th,
.ant-table table td {
  font-size: 15px;
}

.ant-typography,
.ant-table-cell a,
.addEntry a {
  color: #9c5403 !important;
  /* text-decoration: underline !important; */
  font-size: 0.8rem;
}

.ant-slider-handle {
  background: #fba03a !important;
  width: 22px !important;
  height: 22px !important;
  border: 0px !important;
  margin-top: -10px !important;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #9f5a05;
  border-color: #ffffff;
  border-radius: 5px;
 
}  
.ant-checkbox>.ant-checkbox-inner{
  height: 21px;
  width: 21px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after{
  transform: rotate(45deg) scale(1.5) translate(-33%,-50%);
  
}
.ant-checkbox.ant-checkbox-checked::after{
  border: none;
}

button.ant-switch-checked {
  background-color: #9c5403 !important;
}
.error,
.success,
.warning {
  font-size: 0.9rem;
}
.error {
  color: red;
  margin-top: -20px;
  margin-left: 3px
}

.success {
  color: green;
}

.warning {
  color: orange;
}

@media (max-width: 480px) {
  .tableTop {
    display: block;
  }

  .tableTop .actions {
    text-align: center;
  }

  .componentsLoadContainer {
    margin: 5px 0;
    border-radius: 0px;
    padding: 1%;
  }

  button.ant-btn {
    /* width: 60%; */
  }
}
