.isSoldRow {
  background-color: rgba(243, 188, 116, 0.398);
}
.statagy {
  margin: 15px;
  border-radius: 10px;
  background-color: #fff;
  overflow: auto;
  font-size: 12px;
}
.custom-table {
  overflow: scroll;
  position: relative; /* Required for pseudo-elements */
  white-space: nowrap;
}
.custom-table::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar track */
  height: 2px; /* Reduce the height of the scrollbar track */
}

/* Customize the scrollbar thumb */
.custom-table::-webkit-scrollbar-thumb {
  background-color: #fba03b; /* Thumb color */
  border-radius: 3px; /* Round the edges of the thumb */
}
.inputSearch {
  width: 30%;
  display: flex;
  justify-content: space-around;
}
.inputSearch1:hover {
  /* box-shadow: ; */
  border: solid, rgb(0, 140, 255), 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .searchIcon {
    box-shadow: #686868;
    rotate: 100deg;
    transition-duration: 0.5s;
  }
}
.ant-carousel .slick-slider {
  display: flex;
  width: 100%;
  .carousel-arrow {
    margin-top: 24%;
    .ant-btn .ant-btn-default {
      background-color: #fff;
    }
  }
}
.imgCarousel {
  max-height: 300px;
  padding: 1px;
  float: right;
  margin-bottom: 2%;
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  .img {
    height: 300px;
    width: 100%;
  }
}
.vehicleDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  /* width:20rem; */
  .card {
    width: 30%;
    margin-bottom: 1%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}

.ant-input-group .ant-input {
  border-radius: 30px !important;
  border-top-right-radius: 40px;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  margin-left: 10px;
}
.cards {
  display: flex;
  justify-content: flex-end;
  padding: 1%;
  .svg svg {
    height: "20px";
    width: "20px";
  }
  .card .ant-card-body {
    padding: 5px;
  }
  .card {
    padding: 0px !important;
    width: 150px;
    /* text-align: center; */
    margin-right: 2%;
    min-height: 20%;
    border-radius: 10px;
    border-left: 4px solid #fba03b;
    cursor: default;
    .statisticCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .ant-statistic-title,
    .ant-statistic-content {
      text-wrap: nowrap;
      margin-left: 20px;
    }
  }
  .card {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}
.statagy .tableTop {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 4px 16px;
}
.tableContainer {
  display: flex;
  justify-content: space-around;
  padding: 1%;
}
.tableContant {
  /* width: 48%; */
  padding: 1%;
}
.divCarousel {
  width: 50%;
  padding: 2%;
}

.custom-scrollbar-container {
  margin-left: 8%;
  padding: 5px;
  overflow-x: scroll;
}
.custom-scrollbar-container::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar track */
  height: 2px; /* Reduce the height of the scrollbar track */
}

/* Customize the scrollbar thumb */
.custom-scrollbar-container::-webkit-scrollbar-thumb {
  background-color: #fba03b; /* Thumb color */
  border-radius: 3px; /* Round the edges of the thumb */
}
@media (max-width: 480px) {
  .ant-carousel .slick-slider {
    .carousel-arrow {
      margin-top: 8rem;
    }
    .inputSearch1:focus {
      /* box-shadow: ; */
      border: rgb(0, 140, 255), 0.1px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      .searchIcon {
        box-shadow: #686868;
        rotate: 100deg;
        transition-duration: 0.5s;
      }
    }
  }
  .imgCarousel {
    max-height: 300px;
    padding: 0px;
    margin-bottom: 0%;
    width: 100%;
    .img {
      height: 250px;
      width: 100%;
    }
  }

  .divCarousel {
    width: 100%;
    padding: 2%;
    margin-top: 1%;
  }
  .vehicleDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .card {
      margin-bottom: 4%;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 1%;
    .card .ant-card-body {
      padding: 5px;
    }
    .card {
      padding: 0px !important;
      min-width: 100%;
      min-height: 15%;
      border-radius: 10px;
      border-left: 4px solid #fba03b;
      cursor: default;
      margin-bottom: 10px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
        rgba(0, 0, 0, 0.23) 0px 6px 6px;

      .statisticCard {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
      .ant-statistic-title {
        text-wrap: nowrap;
        /* margin-left: 20px;  */
      }
    }
    .card .ant-card-body {
      padding: 5px;
      height: 69px;
      margin-bottom: 0px;
    }
  }
  .tableContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1%;
    justify-content: space-around;
    padding: 1%;
    .tableContant {
      width: 100%;
      margin-top: 1%;
      .ant-table {
        overflow-y: scroll;
      }
    }
  }
  .filterTxt {
    float: right;
  }
  .custom-scrollbar-container {
    overflow: scroll;
    margin-left: -5px;
  }
}

.statagy .ant-table-thead th {
  border-bottom-color: #facdcd;
  color: #686868;
}

.statagy td.ant-table-cell {
  color: #000;
}

.greenBadge {
  background-color: #1db700;
  padding: 4px 7px;
  color: #fff;
  border-radius: 6px;
}

.redBadge {
  background-color: #b70000;
  padding: 7px 15px;
  color: #fff;
  border-radius: 6px;
}

.orangeBadge {
  background-color: #b77a00;
  padding: 7px 15px;
  color: #fff;
  border-radius: 6px;
}

.statagy .ant-table table {
  font-size: 15px;
}

.statagy .ant-image-img {
  width: auto !important;
}

.custom-table .ant-table-tbody > tr > td,
.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table tfoot > tr > td,
.custom-table .ant-table tfoot > tr > th {
  padding: 7px;
  font-size: 12px;
}
.ant-modal-body {
  padding: 8px;
}

.ant-modal-content > .ant-modal-footer > button {
  height: 23px;
  max-width: 105px;
  font-size: 10px;
  justify-items: center;
}

button.filterBtn {
  height: 23px;
  max-width: 105px;
  /* justify-items: center; */
}

@media (max-width: 640px) {
  .statagy .ant-table-content {
    overflow-x: auto;
  }
}
