.App {
  text-align: center;
}
.InwardContainer{
  .InwardSteps{
    padding: 1%;
    margin: 15px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
  }
  .steps-action, .steps-content{
    margin-left: 1%;
    margin-top: 2%;
    width: 100%;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  /* scrollbar-width:0.1px; */
  width: 5px;
  border-radius:10px;
  margin-right:5px;
  overflow-x:hidden;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fba03b;
  border-radius: 20px;
  height: 6px;
  width:2px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
