.toggleContainer{
    display: flex;
}
.statagy {
    /* margin: 15px; */
    border-radius: 10px;
    background-color: #fff;
    overflow: auto;
    font-size: 12px;
}
.nextBtn{
    margin-left: -140px;
    margin-top: 250px;
    position: absolute,
}
.statagy .tableTop {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 4px 16px;
}

.accLinks {
    .createDealer {
        font-size: 30px;
        color: #fba03b;
        /* margin-top: -6px; */
    }
}

/* .statagy .createNew {
    background-color: #9c5403;
    color: #fff;
    border: 0;
    border-radius: 5px;
} */

.statagy .ant-table-thead th {
    border-bottom-color: #facdcd;
    color: #686868
}

.statagy td.ant-table-cell {
    color: #000;
}

.greenBadge {
    background-color: #1DB700;
    padding: 4px 7px;
    color: #fff;
    border-radius: 6px;
}

.redBadge {
    background-color: #b70000;
    padding: 7px 15px;
    color: #fff;
    border-radius: 6px;
}

.orangeBadge {
    background-color: #b77a00;
    padding: 7px 15px;
    color: #fff;
    border-radius: 6px;
}

.statagy .ant-table table {
    font-size: 15px;
}

.statagy .ant-image-img {
    width: auto !important;
}

.custom-table .ant-table-tbody>tr>td,
.custom-table .ant-table-thead>tr>th,
.custom-table .ant-table tfoot>tr>td,
.custom-table .ant-table tfoot>tr>th {
    padding: 7px;
    font-size: 12px;
}

.ant-modal-content {
    width: 170%;
    margin-left: -35%;

}

.ant-modal-content>.ant-modal-footer>button {
    height: 23px;
    max-width: 105px;
    font-size: 10px;
    justify-items: center;
}

button.filterBtn {
    height: 23px;
    max-width: 105px;
    /* justify-items: center; */
}

@media (max-width: 640px) {
    .statagy .ant-table-content {
        overflow-x: auto;
    }
    .nextBtn{
        margin-left: -140px;
        position: relative;
    }
    
    .ant-modal-content {
        width: 90%;
        margin-left: 8%;

    }
    .toggleContainer{
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
        .toggleSearch{
            margin-left: 0%;
            width: 90% !important;
            min-height: 300px !important;
            /* border-radius: 2%; */
            .Form{
                display: flex !important;
                .toggleBtn{
                    margin-left: 11% !important;
                    display: flex !important;
                    justify-content: center !important;
                }
            }
        }
        .componentsLoadContainer{
            width: 100% !important;
        }

    }

    .componentsLoadContainer{
        /* width: 90% !important; */
    }
}

.vehicleDetails {
    display: flex;
    justify-content: space-evenly;
}

.flip-card {
    background-color: transparent;
    width: 30%;
    height: 310px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: start;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 15px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 10%;

}

.flip-card-front {
    background-color: #f7f6f5;
    color: black;
}

.flip-card-back {
    background-color: #f7f6f5;
    /* color: white; */
    transform: rotateY(180deg);
}