.Row{
    display: flex;
    flex-wrap:wrap;
}
.textBoxContainer1{
    display: flex;
    .textBoxContant{
        width: 282px; 
        margin-right: 5%; 
    }
}
.textBoxContainer2{
    display: flex;
    flex-wrap:wrap; 
}

@media (max-width: 480px){
    .Form{
        display: flex;
        flex-wrap: wrap;
    .Row{
        flex-direction: column;
     
        .textBoxContant{
        width: 190px; 
        display: flex;
        flex-direction: column;
    }}}
    .textBoxContainer1, .textBoxContainer2{
        display: flex;
        flex-direction: column;
    }
}