.header {
  width: 100%;
  height: 70px;
  position: fixed;
  margin-top: -1%;
  z-index: 999;
  background-color: #fff;
  .menuLinks {
    font-size: 16px;
  }
  .menuItems {
    display: flex;
    justify-content: center;
    margin-top: -75px;
  }
  .userBtn {
    z-index: 1999;
  }
  .menueBtn {
    display: none;
  }
  .logo {
    width: 180px;
  }
}
img.ant-image-img {
  width: 50%;
  vertical-align: middle;
}
@media (max-width: 805px) {
 .header {

    .menuItems {
        display: none;
      }
      .menueBtn {
        display: block;
        margin-top: 5px;
      }  
      .menu {
        z-index:5;
        display: flex;
        flex-direction: column;
        height: 10.8rem;
        padding: 10px;
        background-color: #fff;
        position: absolute;
        margin: 5px;
        margin-top: 80px;
        width: 50%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      .userBtn{
        z-index:1;
        /* margin-left: 22px; */
    }
      .menuLinks,
      .menuLinks a {
        display: block !important;
      }
 }
}

@media (max-width: 480px) {
  .header {
    position: relative;
    margin-top: 0%;
    display: flex;
    justify-content: space-between;

    .menuItems {
      display: none;
    }

    .container {
      display: flex;
      justify-content: space-evenly;
    }

    .menu {
      z-index:5;
      height: 10.8rem;
      padding: 10px;
      background-color: #fff;
      position: absolute;
      margin: 5px;
      margin-top: 80px;
      width: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    
    .menueBtn {
      display: block;
      margin-top: 5px;
    }

    .logo {
      width: 150px;
      margin-left: -3%;
    }
    
    .userBtn{
        z-index:1;
        margin-left: 22px;
    }
  }
  .menuLinks,
  .menuLinks a {
    display: block !important;
  }
}

.header .ant-col {
  padding: 20px 10px;
}

.header a.ant-typography,
.header a {
  color: #000;
  padding: 7px 0;
}

.header a.ant-typography:hover,
.header a:hover,
.header a.ant-typography.active,
.header a.active {
  color: #fba03a;
  border-bottom: 1px solid #fba03b;
}

.header .anticon-bell svg {
  fill: #fba03a;
  font-size: 18px;
}

.ant-btn-default {
  background-color: #fba03a !important;
  color: #fff;
  border: 0px;
  border-radius: 5px;
  padding: 0 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.menuLinks {
  display: flex;
  white-space: nowrap;
}
